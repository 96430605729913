body {
  background-color: black;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auth {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: xx-large;
  justify-content: center;
  padding: 5px;
}

.welcome-video {
  width: 98vw;
}

.video-credit {
  font-size: xx-small;
  margin-top: 2px;
  margin-bottom: 0px;
}

.auth-form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.auth-input {
  width: 90%;
  font-size: xx-large;
}

.auth-input-button {
  margin-top: 20px;
  font-size: xx-large;
  color: black;
}

.character-rules {
  padding: 20px;
}

.character-info-header {
  margin-bottom: 5px;
}

.character-info {
  margin-top: 5px;
}

.rule-list {
  list-style-type: none;
  padding-left: 0px;
}

.rule-list-item {
  margin: 10px;
  padding-bottom: 25px;
}

.rule-button {
  font-size: x-large;
  color: black;
}

.rule {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 90vh;
}

.rule-trigger {
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.link-rule {
  font-size: xx-large;
  color: white;
}

.back-button {
  font-size: xx-large;
  margin-top: auto;
  color: black;
}

.character-background {
  padding: 20px;
  font-size: large;
}

.character-background-header,
.character-action-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 5px;
  width: 75vw;
}

.character-background-content {
  margin-top: 5px
}

.character-background-send-container {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.character-background-send {
  font-size: xx-large;
  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
  border-radius: 10px;
}

.clue-board {
  background-color: rgb(216, 210, 187);
  border: rgb(73, 16, 16) solid 4px;
  margin: 10px;
  display: grid;
  padding: 10px;
}

.clue {
  background-color: rgb(255, 255, 153);
  box-shadow: #333333;
  border: rgb(111, 49, 49) solid 1px;
  margin: 5px;
  padding: 10px;
  color: black;
}

.clue-heading {
  margin: 0px
}

.suspect-list {
  display: flex;
  flex-direction: column;
}

.suspect-name-header {
  margin-bottom: 0px;
}

.suspect-bio {
  margin-top: 5px;
}

.suspect-button {
  color: black;
}

.back-button-suspect {
  align-self: center;
  color: black;
}